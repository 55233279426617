export const SwapOff = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2 12C1.2 6.1 6.1 1.2 12 1.2C17.9 1.2 22.8 6.1 22.8 12C22.8 17.9 17.9 22.8 12 22.8C6.1 22.8 1.2 17.9 1.2 12ZM2.8 12C2.8 17.1 6.9 21.2 12 21.2C17.1 21.2 21.2 17.1 21.2 12C21.2 6.9 17.1 2.8 12 2.8C6.9 2.8 2.8 6.9 2.8 12Z"
        fill="black"
      />
      <path
        d="M15.2 15.8L7 15.8C6.6 15.8 6.2 15.5 6.2 15C6.2 14.5 6.5 14.2 7 14.2L15.2 14.2L13.5 12.5C13.2 12.2 13.2 11.7 13.5 11.4C13.8 11.1 14.3 11.1 14.6 11.4L17.6 14.4C17.9 14.7 17.9 15.2 17.6 15.5L14.6 18.5C14.3 18.8 13.8 18.8 13.5 18.5C13.2 18.2 13.2 17.7 13.5 17.4L15.2 15.8ZM8.8 8.19999L17 8.19999C17.4 8.19999 17.8 8.49999 17.8 8.99999C17.8 9.49999 17.4 9.79999 17 9.79999L8.8 9.79999L10.5 11.5C10.8 11.8 10.8 12.3 10.5 12.6C10.2 12.9 9.7 12.9 9.4 12.6L6.4 9.59999C6.1 9.29999 6.1 8.79999 6.4 8.49999L9.4 5.49999C9.7 5.19999 10.2 5.19999 10.5 5.49999C10.8 5.79999 10.8 6.29999 10.5 6.59999L8.8 8.19999Z"
        fill="black"
      />
    </svg>
  );
  
  export const SwapOn = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.2 12C2.2 6.65228 6.65228 2.2 12 2.2C17.3477 2.2 21.8 6.65228 21.8 12C21.8 17.3477 17.3477 21.8 12 21.8C6.65228 21.8 2.2 17.3477 2.2 12Z"
        fill="#B1FFAF"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M15.2 15.8L7 15.8C6.6 15.8 6.2 15.5 6.2 15C6.2 14.5 6.5 14.2 7 14.2L15.2 14.2L13.5 12.5C13.2 12.2 13.2 11.7 13.5 11.4C13.8 11.1 14.3 11.1 14.6 11.4L17.6 14.4C17.9 14.7 17.9 15.2 17.6 15.5L14.6 18.5C14.3 18.8 13.8 18.8 13.5 18.5C13.2 18.2 13.2 17.7 13.5 17.4L15.2 15.8ZM8.8 8.19999L17 8.19999C17.4 8.19999 17.8 8.49999 17.8 8.99999C17.8 9.49999 17.4 9.79999 17 9.79999L8.8 9.79999L10.5 11.5C10.8 11.8 10.8 12.3 10.5 12.6C10.2 12.9 9.7 12.9 9.4 12.6L6.4 9.59999C6.1 9.29999 6.1 8.79999 6.4 8.49999L9.4 5.49999C9.7 5.19999 10.2 5.19999 10.5 5.49999C10.8 5.79999 10.8 6.29999 10.5 6.59999L8.8 8.19999Z"
        fill="black"
      />
    </svg>
  );